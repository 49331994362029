import React, {
    useContext,
    useReducer,
    useState
} from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import CreateCaseForm from '../components/sections/createCase/CreateCaseForm';
import { useNavigate } from 'react-router-dom'
import { apiRoute } from '../App';
import { CaseContext } from '../context/CaseContext';

const CreateCaseView = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const today = new Date();

    const {
        agencyCode,
        courtNum,
        divisionCode,
        caseTypes,
        statusCodes,
        suffixCodes,
        caseYear,
    } = useContext(CaseContext);

    const initialBondState = {
        caseTypes: '',
        clerkCaseNum: '',
        citNumber: '',
        violationDate: '',
        courtDatetime: '',
        dateFiled: `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`,
        lastName: '',
        firstName: '',
        midInit: '',
        suffix: '',
        agency: '',
        court: courtNum[0]?.courtNum ?? '',
        division: '',
        caseStatus: '',
        caseYear: caseYear[0] ?? today.getFullYear(),
        arrestNumber: '',
        errors: {}
    };

    const reducer = (state, action) => {
        switch (action.type) {
            case 'UPDATE_FIELD':
                return {
                    ...state,
                    [action.field]: action.value,
                    errors: {
                        ...state.errors,
                        [action.field]: action.error,
                    },
                };
            case 'UPDATE_FIELD_ERROR':
                return {
                    ...state,
                    errors: {
                        ...state.errors,
                        [action.field]: action.error,
                    }
                };
            case 'CLEAR_FIELD_ERROR':
                const updatedErrors = { ...state.errors };
                delete updatedErrors[action.field];
                return {
                    ...state,
                    errors: updatedErrors,
                };
            default:
                return state;
        }
    };


    const handleFieldChange = (field, value) => {
        dispatch({ type: 'UPDATE_FIELD', field, value });
    };

    const handleBlur = async (field, value) => {
        let error = null;
        if (field === 'citNumber' || field === 'clerkCaseNum' || field === 'arrestNumber') {
            if (!value.trim()) {
                error = 'Citation, Arrest, or Case Number is required';
            } else {
                // Clear the error for the opposite fields when one of them has a value
                const allFields = ['citNumber', 'clerkCaseNum', 'arrestNumber'];
                const oppositeFields = allFields.filter(elem => elem !== field)
                oppositeFields.forEach(elem => dispatch({ type: 'CLEAR_FIELD_ERROR', field: elem }))
            }
        } else {
            switch (field) {
                case 'caseTypes':
                    if (!value) error = 'Case Type is required';
                    dispatch({ type: 'CLEAR_FIELD_ERROR', field: 'caseTypes' })
                    break;
                case 'caseYear':
                    if (!value || value.length < 4 || value.length > 4) {
                        error = 'Case Year is required';
                    }
                    dispatch({ type: 'CLEAR_FIELD_ERROR', field: 'caseYear' })
                    break;
                case 'violationDate':
                    if (!value) {
                        error = 'Violation Date is required';
                    } else if (new Date(value) > today) {
                        error = 'Cannot be a future date.';
                    }
                    dispatch({ type: 'CLEAR_FIELD_ERROR', field: 'violationDate' })
                    break;
                case 'dateFiled':
                    if (!value) {
                        error = 'Date Filed is required';
                    } else if (new Date(value) > today) {
                        error = 'Cannot be a future date.';
                    }
                    dispatch({ type: 'CLEAR_FIELD_ERROR', field: 'dateFiled' })
                    break;
                case 'courtDatetime':
                    if (!value) {
                        error = "Pay By Date is required"
                    }
                    if (new Date(value) < new Date(state.violationDate)) {
                        error = 'Cannot be before violation date.';
                    }
                    dispatch({ type: 'CLEAR_FIELD_ERROR', field: 'courtDatetime' })
                    break;
                case 'firstName':
                    if (!value) {
                        error = 'First Name is required';
                    }
                    dispatch({ type: 'CLEAR_FIELD_ERROR', field: 'firstName' })
                    break;
                case 'lastName':
                    if (!value) {
                        error = 'Last Name is required';
                    }
                    dispatch({ type: 'CLEAR_FIELD_ERROR', field: 'lastName' })
                    break;
                default:
                    break;
            }
        }

        dispatch({ type: 'UPDATE_FIELD', field, value, error });
    };

    const [state, dispatch] = useReducer(reducer, initialBondState);

    const createCitation = async (e) => {
        e.preventDefault();

        try {
            const newErrors = {};
            const fieldsToCheck = ['violationDate', 'courtDatetime', 'dateFiled', 'firstName', 'lastName', 'caseYear', 'caseTypes', 'citNumber', 'clerkCaseNum', 'arrestNumber'];

            fieldsToCheck.forEach(field => {
                const value = state[field];
                let error = null;

                switch (field) {
                    case 'citNumber':
                    case 'arrestNumber':
                    case 'clerkCaseNum':
                        if (!value.trim()) {
                            error = 'Citation, Arrest or Case Number is required';
                        } else {
                            // Clear the error for the opposite field when one of them has a value
                            const allFields = ['citNumber', 'clerkCaseNum', 'arrestNumber'];
                            const oppositeFields = allFields.filter(elem => elem !== field)
                            oppositeFields.forEach(elem => dispatch({ type: 'CLEAR_FIELD_ERROR', field: elem }))
                            newErrors[field] = null;
                        }
                        break;
                    case 'caseTypes':
                        if (!value) {
                            error = 'Case Type is required';
                            newErrors[field] = error;
                        }
                        break;
                    case 'caseYear':
                        if (!value || value.toString().length !== 4) {
                            error = 'Case Year is required and must be 4 digits';
                            newErrors[field] = error;
                        }
                        break;
                    case 'violationDate':
                        if (!value) {
                            error = 'Violation Date is required';
                            newErrors[field] = error;
                        } else if (new Date(value) > today) {
                            error = 'Cannot be a future date.';
                            newErrors[field] = error;
                        }
                        break;
                    case 'dateFiled':
                        if (!value) {
                            error = 'Violation Date is required';
                            newErrors[field] = error;
                        } else if (new Date(value) > today) {
                            error = 'Cannot be a future date.';
                            newErrors[field] = error;
                        }
                        break;
                    case 'courtDatetime':
                        if (!value) {
                            error = "Pay By Date is required";
                            newErrors[field] = error;
                        } else if (new Date(value) < new Date(state.violationDate)) {
                            error = 'Cannot be before violation date.';
                            newErrors[field] = error;
                        }
                        break;
                    case 'firstName':
                        if (!value) {
                            error = 'First Name is required';
                            newErrors[field] = error;
                        }
                        break;
                    case 'lastName':
                        if (!value) {
                            error = 'Last Name is required';
                            newErrors[field] = error;
                        }
                        break;
                    default:
                        delete newErrors[field];
                        break;
                }
            });

            const errors = [];
            Object.values(state.errors).forEach(error => {
                if (typeof error !== 'object') {
                    if (error !== undefined) {
                        errors.push(error);
                    }
                }
            })


            Object.keys(newErrors).forEach(field => {
                dispatch({ type: 'UPDATE_FIELD', field, value: state[field], error: newErrors[field] });
            });

            if (errors.length) {
                return;
            }

            if (state.clerkCaseNum === '' && state.citNumber === '' && state.arrestNumber === '') {
                setLoading(false);
                await toast.error('Citation, Arrest or Case Number is required', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                return;
            }

        } catch (error) {
            console.log(error);
            return;
        }


        try {
            setIsSubmitting(true);
            const citationData = {
                citNumber: state.citNumber || null,
                courtDatetime: state.courtDatetime || '9999-12-31',
                lastName: state.lastName,
                firstName: state.firstName,
                midInit: state.midInit,
                suffix: state.suffix,
                IssueAgency: state.agency,
                court: state.court,
                DivisionCode: state.division,
                caseStatus: state.caseStatus,
            };
            const caseYear = !state?.caseYear ? '' : state?.caseYear;
            const caseNum = !state?.clerkCaseNum ? 'noCase' : state?.clerkCaseNum;
            const caseType = !state?.caseTypes ? 'noCaseType' : state?.caseTypes;
            const vioDate = !state?.violationDate ? 'noDate' : state?.violationDate;
            const arrestNumber = !state?.arrestNumber ? 'noArrest' : state?.arrestNumber;

            setLoading(true);
            const { data } = await axios.post(`${apiRoute}/api/citationtbls/${vioDate}/${caseNum}/${caseType}/${caseYear}/${state.dateFiled}/${arrestNumber}`, citationData)
            setIsSubmitting(false);
            setLoading(false);
            navigate(`/view/${data.caseTbl.pkCaseId}`);
            await toast.success('Case Created!', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        catch (err) {
            setIsSubmitting(false);
            setLoading(false);
            if (err.response.data) {
                await toast.error(err.response.data, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                return;
            }
            await toast.error('Unknown Error Occurred, please reach out to support', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    };



    return (
        <CreateCaseForm
            loading={loading}
            handleFieldChange={handleFieldChange}
            handleBlur={handleBlur}
            state={state}
            isSubmitting={isSubmitting}
            createCitation={createCitation}
            caseTypes={caseTypes}
            caseYear={caseYear}
            divisionCode={divisionCode}
            courtNum={courtNum}
            statusCodes={statusCodes}
            suffixCodes={suffixCodes}
            agencyCode={agencyCode}
        />

    )
}

export default CreateCaseView;